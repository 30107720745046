import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';

import { addConsultant } from '../actions/consultantActions';
import { initialize } from '../actions/userActions';
import Header from '../components/ConsultantHeader';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import LoadingLogo from '../components/LoadingLogo';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import { loadingEndAction } from '../reducers/loadingReducer';
import debug from '../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    noConsultant: {
      height: 1000,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    },
    noConsultantItem: {
      margin: '1%',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    nav: {
      width: '210px',
      background: '#20232A',
      minHeight: '50rem',
    },
    content: {
      width: '1350px',
      margin: '64px auto',
    },
    menuStart: {
      margin: '2rem 0',
    },
    menuPending: {
      margin: '2rem 0',
    },
    menuEnd: {
      margin: '2rem 0',
    },
    menuButton: {
      margin: '0.5rem 0',
      color: '#3f424f',
      display: 'flex',
      justifyContent: 'left',
    },
  });

const ConsultantLayout = ({ classes, children }: Props): React.ReactElement => {
  debug('Load consultant layout');
  const { id: uid, userType } = useSelector((appState: AppState) => appState.user);
  const { id: consultantId, loaded: consultantLoaded } = useSelector(
    (appState: AppState) => appState.consultant
  );
  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const { isVisible, severity, message } = useSelector((state: AppState) => state.notification);
  const [checked, setChecked] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(userType));
    dispatch(loadingEndAction());
  }, []);

  const NoConsultant = (
    <Grid container direction="column" className={classes.noConsultant}>
      <Grid item className={classes.noConsultantItem}>
        <Typography style={{ fontSize: 25 }}>你还不是顾问</Typography>
        <Typography variant="subtitle2" align="center">
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
            color="primary"
          />
          请确认阅读 《
          <span>
            <a
              href="https://about.fintegrity.cn/user"
              target="_blank"
              className={classes.a}
              rel="noopener noreferrer"
            >
              用户协议
            </a>
          </span>
          》和《
          <span>
            <a
              href="https://about.fintegrity.cn/privacy"
              target="_blank"
              className={classes.a}
              rel="noopener noreferrer"
            >
              查看隐私政策
            </a>
          </span>
          》并同意遵傅通自由职业平台的用户协议和隐私政策
        </Typography>
      </Grid>
      <Grid item className={classes.noAdminItem}>
        <Button
          disabled={!checked ? true : false}
          onClick={() => {
            dispatch(addConsultant());
          }}
          variant="contained"
          color="primary"
          style={{
            width: '20%',
          }}
        >
          注册成为顾问
        </Button>
      </Grid>
    </Grid>
  );

  if (uid && !consultantLoaded && !consultantId && !isLoading) {
    return <>{NoConsultant}</>;
  }

  if (uid && children) {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
        </Helmet>
        <div style={{ position: 'fixed', zIndex: '9999' }}>
          <Header />
        </div>
        <div className={classes.container}>
          <div className={classes.content}>{children}</div>
        </div>
        <Footer />
        {isLoading && <Loading />}
        {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
      </>
    );
  }

  return <LoadingLogo />;
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
  verificationHide?: boolean;
}

export default withStyles(styles)(ConsultantLayout);
