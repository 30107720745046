import React from 'react';

import ConsultantLayout from '../../layout/ConsultantLayout';
import LogoffUserView from '../../views/consultant/LogoffUserView';

const ConsultantResumePage = (): React.ReactElement => {
  return (
    <>
      <ConsultantLayout>
        <LogoffUserView />
      </ConsultantLayout>
    </>
  );
};

export default ConsultantResumePage;
