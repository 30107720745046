import React, { ReactElement } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { createAuthenticationInfo } from '../../model/authentication.model';

const styles = (): StyleRules =>
  createStyles({
    box: {
      padding: '1rem 0',
      margin: '1rem auto',
      background: '#fff',
    },
    titleBox: {
      padding: '1rem 0',
      borderBottom: '1px solid rgb(64, 64, 64,0.3)',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      marginLeft: '0.5rem',
    },
    note: {
      fontSize: '0.75rem',
      color: '#242f65',
    },
    marginBox: {
      marginTop: '1rem',
    },
    attachment: {
      marginBottom: '1rem',
    },
    image: {
      position: 'relative',
      width: '5rem',
      height: '5rem',
      marginRight: '1rem',
    },
    delIcon: {
      position: 'absolute',
      top: '-0.5rem',
      right: '-0.75rem',
      color: '#f81d22',
      cursor: 'pointer',
    },
  });

const LogoffUserView = ({ classes }: Props): ReactElement => {
  const { control, getValues, trigger } = useForm({
    defaultValues: {
      authenticationInfo: createAuthenticationInfo(),
    },
    mode: 'onChange',
  });
  getValues;
  trigger;
  const { errors, isValid, isDirty } = useFormState({ control });
  isValid;
  isDirty;
  // const { fields: AuthenticationList, append, remove } = useFieldArray({
  //   control,
  //   name: 'authenticationInfo.authenticationList',
  // });

  // const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  // const compressFile = async (fileToCompress: File): Promise<string> => {
  //   const options = {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //     useWebWorker: true,
  //   };
  //   let compressedFile: File;
  //   try {
  //     compressedFile = await imageCompression(fileToCompress, options);
  //     debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
  //   } catch (error) {
  //     debug('Compress file', error);
  //   }
  //   return new Promise(resolve => {
  //     const reader = new FileReader();
  //     reader.onloadend = (): void => resolve(reader.result as string);
  //     reader.readAsDataURL(compressedFile);
  //   });
  // };

  // const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
  //   setIsUploadDialogOpen(false);
  //   debug('Handle on save', fileObjects);
  //   for (let index = 0; index < fileObjects.length; index++) {
  //     const element = fileObjects[index].file;
  //     const fileToUpload = await compressFile(element);
  //     append({ id: undefined, file: fileToUpload });
  //   }
  // };

  const handleSubmit = () => {
    console.log(control);
  };

  // const isRenderUpload = useMemo(() => {
  //   return AuthenticationList.length < 2;
  // }, [AuthenticationList]);

  return (
    <Grid xs={12} className={classes.box} container>
      <Grid
        item
        xs={12}
        className={classes.titleBox}
        container
        justifyContent="center"
        alignItems="center"
      >
        <div className={classes.titleContainer}>
          <h2>
            <VerifiedUserIcon />
            <span className={classes.title}>注销账号</span>
          </h2>
          <div className={classes.note}>账号注销后将不能恢复</div>
        </div>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={8} container justifyContent="center">
          <Grid xs={8} style={{ marginTop: '2rem' }}>
            账号：
          </Grid>
          <Grid xs={8} className={classes.marginBox}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="手机号"
                  type="text"
                  fullWidth
                  error={!!errors.authenticationInfo?.phone}
                  helperText={!!errors.authenticationInfo?.phone && '手机号不能为空'}
                  {...field}
                />
              )}
              name="authenticationInfo.phone"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid
            xs={8}
            justifyContent="space-between"
            alignItems="center"
            container
            className={classes.marginBox}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="验证码"
                  type="text"
                  style={{
                    width: '80%',
                  }}
                  error={!!errors.authenticationInfo?.code}
                  helperText={!!errors.authenticationInfo?.code && '验证码不能为空'}
                  {...field}
                />
              )}
              name="authenticationInfo.code"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
            <Button type="submit" variant="contained" color="primary">
              发送验证码
            </Button>
          </Grid>

          <Grid
            xs={8}
            container
            justifyContent="center"
            alignItems="center"
            className={classes.marginBox}
          >
            <Checkbox
              defaultChecked
              color="default"
              inputProps={{ 'aria-label': 'checkbox with default color' }}
            />
            <Typography variant="body2" align="left" color="secondary">
              同意并了解账号注销
            </Typography>
          </Grid>
          <Grid
            xs={8}
            container
            justifyContent="center"
            alignItems="center"
            className={classes.marginBox}
          >
            <Button
              style={{ width: 120, height: 56 }}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              提交
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(LogoffUserView);
